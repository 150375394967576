//TODO Dynimise APIEndpoint and GoogleAPIKey
const env = 'stage';
const rootDomain = 'iaas-platform.axa'
const APIEndpoint = `https://uber.income-protection.${env}.${rootDomain}`;
const APIFrontendEndpoint = `https://uber.income-protection-front.${env}.${rootDomain}`;

const Config = {
  inlineTextSizeLimit: 256,
  descriptionSizeLimit: 500,
  tncURL: '/Files/1.2.0_AXA_XL_UK_Uber_Vehicle_Interruption_Cover_IPID_two_claims_limit.pdf',
  APIEndpoint: `${APIEndpoint}/v1`,
  APIFrontendEndpoint,
  GoogleMapAPIEndpoint: `${APIFrontendEndpoint}/googlemap`,
  GoogleMapAPIKey: 'AIzaSyCv590PaLeGuycShVI2X9aFiitaZMnPr0E',
  UploadEndpoint: `${APIFrontendEndpoint}/upload`,
  driverId: '809769d6-d5a4-487d-bf1b-57e9d5b965c0', // Not used but usefull for test purpose on demo env
  prismicEndpoint: 'https://uber-income-protection.cdn.prismic.io/api/v2',
  prismicKey:
    'MC5YUjRmS0JBQUFDVUF6Vzl2.DS4aCRkiO--_vQzvv70Ga--_vRvvv70l77-977-977-9K37vv73vv73vv70a77-977-9Ru-_vWHvv70l',
  uberTokenApi: `https://login.uber.com/oauth/v2/authorize?response_type=code&client_id=fL_S5ML_pw95sa9kP7aU0iMhgafLxPHY&redirect_uri=${APIFrontendEndpoint}/authorization-code`,
};
export default Config;
